import { format } from "date-fns";
import packageJSON from "../../package.json";

/**
 * Setup React ENV variables
 * Resource found here: https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5
 */

/**
 * Update Endpoint & ENV Variables 6/2 at 8:00 AM
 * Azure ENV Endpoint: https://ala-api.azurewebsites.net
 */
const REACT_APP_ENV_AUTH = {
	development: {
		base: "https://localhost:5500/alaservices/v1/",
		user: "x-dev-user",
		password: "J99Hf2i3eY#2pqBj234tD2@H$%",
	},
	// Update 'production' endpoint & web-service creds as of 6/18/2021 at 8:15 AM.
	production: {
		base: "https://api.aladvantage.com/alaservices/v1/",
		user: "x-prod-user",
		password: "7U*hg%53^D*@bq-d@k8f2L$^fd4j",
	},
	testing: {
		base: "https://apitest.aladvantage.com/alaservices/v1/",
		user: "x-test-user",
		password: "M9hf^%2HHf3^$(sn@Kd23p#hsq",
	},
};

const REACT_APP_ENV_APP = () => {
	const date = format(new Date(), "YYYY.MM.DD");
	return `ALA Portal - ${packageJSON.version} ${date}`;
};

const REACT_APP_ENV_APP_VERSION = REACT_APP_ENV_APP();

const {
	development: dev,
	production: prod,
	testing: test,
} = REACT_APP_ENV_AUTH;

// current environment name
const REACT_APP_CURRENT_ENV_NAME = "prod";
// current environment vars
const REACT_APP_CURRENT_ENV = prod;
// const REACT_APP_CURRENT_ENV = prod;

const isDevOrTest = () => {
	const env = process.env.NODE_ENV;
	console.log(`ENV: ${env}`);
	return env === "development" || env === "test";
};

// checks if current envionrment is 'localhost' or '127.0.0.1'
const isLocalhost = () => {
	return Boolean(
		window.location.hostname === "localhost" ||
			// [::1] is the IPv6 localhost address.
			window.location.hostname === "[::1]" ||
			// 127.0.0.0/8 are considered localhost for IPv4.
			window.location.hostname.match(
				/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
			)
	);
};

// checks for 'localhost' environment
const isLocal = () => {
	const { hostname } = window.location;

	return hostname === `localhost` || hostname === `127.0.0.1`;
};

/**
 * Determines the current environment's alias name and returns it as a string.
 * @returns {String} - Returns the current environment's name alias (eg. 'test', 'prod', 'dev' or 'local')
 */
const getEnvName = () => {
	if (isLocalhost()) return "local";
	if (isDevOrTest()) return "test";
	return "prod";
};

export { dev, prod, test };

export {
	REACT_APP_CURRENT_ENV as currentEnv,
	REACT_APP_CURRENT_ENV_NAME as currentEnvName,
};

export { REACT_APP_ENV_APP_VERSION as appVersion };

// checks env
export { isDevOrTest, isLocal, isLocalhost, getEnvName };
